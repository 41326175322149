import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Divider,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../../../i18n/config";
 import { useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import  QrReader from '../components/QrReader';

const VerifyCardContainer = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();
  const params = useParams();
  const parm = params.parm;
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("ar");
    }
    setOpenDialog(true);
  }, [cookies.lang]);

  return (
    <Box
      //   p={4}
      mt={-6}

    >
      <p
        style={{
          color: "#FF5E18",
          fontSize: "50px",
          fontWeight: "600",
          textAlign: "center",
          margin: "0px",
        }}
      >
        {i18next.t("verify_card")}
      </p>

        <Box
          sx={{
            backgroundColor: "#15020266",
            borderRadius: "15px",
            p: 1,
            mt: 8,
            mb: 2,
            textAlign: "center",
            color: "white",
          }}
        >
            <QrReader/>
          <Button
            variant="contained"
            className="primary_button"
            sx={{ mt: 4 }}
            onClick={() => {
              navigate("/");
            }}
          >
            {i18next.t("back_home")}
          </Button>
        </Box>
      )}

    </Box>);

};

export default VerifyCardContainer;
